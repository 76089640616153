@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background: black;
  color: #f0f0f0;
}
.archive {
  font-size: 14px;
}
.popup .page {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.tab_open.tab_open {
  top: 0px;
  left: -10.003px;
}
.gridless {
  grid-column: 1 / -1;
  margin-top: 6rem;
}
input {
  color: #101010;
}
.heptagon {
  flex-grow: 0;
  flex-shrink: 1;
  clip-path: polygon(
    0% 35%,
    28% 0%,
    72% 0,
    100% 35%,
    90% 78%,
    50% 98%,
    10% 78%
  );
}
.frontimg {
  width: 300vh;
  height: 110vh;
}
.frontimgwrapper {
  height: 75vh;
}
.start-logged-in,
program-page {
  padding-bottom: 12rem;
}
body.offline .home {
  filter: grayscale(0.9);
}
textarea {
  color: #282030;
}
.border-hover {
  border-top: 0px #32864e solid;
  margin-top: 6px;
  transition: all 0.2s ease-in-out;
  height: 40px;
}
.border-hover.active {
  margin-top: 0px;
  border-top: 6px #32864e solid;
}
.border-hover:hover {
  margin-top: 0px;
  border-top: 5px #32864e solid;
}
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #a7a5a5; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid black; /* creates padding around scroll thumb */
}

select option {
  padding: 3px;
  margin: 3px;
}
.grayscale {
  filter: grayscale(100%);
}
.h-full-minus {
  height: calc(100vh - 120px);
}
.surround-parenthesis::before {
  content: "(";
}
.surround-parenthesis::after {
  content: ")";
}
.surround-parenthesis:empty::before {
  content: "";
}
.surround-parenthesis:empty::after {
  content: "";
}
.admin h2 {
  font-size: 1.5rem;
  line-height: 3rem;
}
.admin h1 {
  font-size: 2rem;
}
.admin h3,
.admin h4 {
  font-size: 1.25rem;
  line-height: 2rem;
}
.active {
  font-weight: bold;
}
.surround-parenthesis::before {
  content: "(";
}
.surround-parenthesis::after {
  content: ")";
}
.surround-parenthesis:empty::before {
  content: "";
}
.surround-parenthesis:empty::after {
  content: "";
}
.max-h-20 {
  max-height: 5rem;
}
.max-h-24 {
  max-height: 6rem;
}
:focus {
  outline: none;
}

.min-h-64 {
  min-height: 10rem !important;
}
.bg-black\/50 {
  background-color: rgb(0 0 0 / 0.5);
}
.button-icon {
  width: 42px;
  min-width: 42px;
  min-height: 42px;
  height: 42px;
  display: flex;
  justify-items: center;
  align-items: center;
}
.button-icon .icon {
  width: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  height: 1.5rem;
}
.button-icon .icon-padded {
  width: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  height: 2rem;
}
.popuptinymce h3 {
  top: 1rem;
  left: 1rem;
  font-size: var(--font-size-800);
  line-height: 2rem;
  font-weight: 700;
}
.tox .tox-toolbar__primary {
  max-height: 38px;
}
.min-h-screen12 {
  min-height: 50vh;
}
.z-10000 {
  z-index: 10000;
}
.admin input[type="text"],
.admin input[type="search"],
.admin input[type="date"],
.admin input[type="datetime-local"] {
  background-color: #101010;
  border-radius: 4px;
  color: #e0e0e0;
}

.admin input[type="datetime-local"]::-webkit-calendar-picker-indicator,
.admin input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  color: #e0e0e0;
}
.w-36vh {
  width: 80vw;
}
.h-36vh {
  height: 36.65vh;
}
.admin .active {
  font-weight: bold;
}
.tinymceview p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.timelinebg {
  background: top left no-repeat fixed;
}
.max-h-1020 {
  max-height: 1100px;
}
.archivesection {
  background-image: url(#/images/drawers/popup_texture.png),
    -webkit-gradient(linear, left top, left bottom, from(#787878), to(#333333));
  border-radius: 3px 3px 0 0;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.6s ease;
}
.archivesectionheader {
  background: #787878;
  background-image: url(#/images/drawers/popup_texture.png);
  padding: 0 20px 0 20px;
  font-weight: bold;
  color: white;
  line-height: 24px;
  border-radius: 5px 5px 0 0;
  margin-left: 13px;
}
.max-h-rest {
  max-height: 300px;
}
.scribble {
  transform: rotate(-4deg);
  font-family: GapsTown;
}
.observationsbg {
  background-color: #205801;
}
.mt-50lvh {
  margin-top: calc(50lvh - 4rem);
}
.search_field {
  border: 1px solid #87ef13;
  line-height: 20px;
  font-size: 16px;
  box-sizing: border-box;
  padding: 15px 10px 15px 40px;
  margin: 0;
  display: block;
  color: #87ef13;
  background: rgba(0, 0, 0, 0.2) url(#/images/observationdb/filters/loop.png)
    left center no-repeat;
}
.w-sm-min {
  max-width: 40rem;
}
.observationlistitem {
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 4px solid rgba(0, 0, 0, 0.4);
  color: rgba(135, 239, 19, 0.8);
  padding: 5px 20px 5px 40px;
  line-height: 20px;
  margin-bottom: 5px;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.8)
    url(#/images/observationdb/map/icon_for_observation.png) 0 5px no-repeat;
}
.ol-zoom-in,
.ol-zoom-out,
.ol-attribution {
  filter: brightness(70%);
}
.ol-zoom {
  right: 0.5em;
  top: 10px;
  left: auto;
}
.ol-no-zoom .ol-zoom {
  display: none;
}
.color-obsgreen {
  color: #87ef13;
}
.bg-black {
  background-color: rgb(0 0 0);
}
.observationfilterButton.selected {
  background-position: 50% -200px;
}
.selected .observationfilterButton {
  background-position: 50% -200px;
}
.observationfilterButton {
  float: left;
  padding: 3px;
  width: 70px;
  height: 70px;
  margin-left: 0px;
  position: relative;
  background: url(#/images/observationdb/filters/FilterButtons.png) 50% 0
    no-repeat;
}
.observationfilterButtonSm {
  float: left;
  padding: 3px;
  width: 40px;
  height: 40px;
  margin-left: 0px;
  background: url(#/images/observationdb/filters/FilterButtons.png) 50% 0
    no-repeat;
}
.observationfilterBg {
  background: url(#/image/filterboxbg.png);
}
.observationfilterButton b,
.observationfilterButtonSm b {
  position: absolute;
  top: -50px;
  left: -55px;
  background: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.8);
  width: 180px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: none;
  font-weight: normal;
}
.observationfilterButton .arrow-down,
.observationfilterButtonSm .arrow-down {
  position: absolute;
  top: -10px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid rgba(0, 0, 0, 0.8);
  display: none;
}

.observationfilterButton:active,
.observationfilterButtonSm:active {
  background-position: 50% -100px;
}
.observationfilterButton .icon,
.observationfilterButtonSm .icon {
  margin: 1px 5px 5px 5px;
}
.observationfilterButton:active .icon,
.observationfilterButton:activesm .icon {
  margin: 3px 5px 5px 5px;
}
.observationfilterButton:active b,
.observationfilterButtonSm:active b {
  display: block;
}
.observationfilterButton:active .arrow-down,
.observationfilterButtonSm:active .arrow-down {
  display: block;
}
.text-white {
  color: white;
}
.fill-current {
  fill: currentColor;
}
.z-100 {
  z-index: 100;
}
.z-25 {
  z-index: 25;
}
.content-visibility-auto {
  content-visibility: auto;
}
.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
.grid-1-1 {
  grid-area: 1 / 1;
}
.-z-1 {
  z-index: -1;
}
.minimapshadow {
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
}
.popupshadow {
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.4);
}
.popup_texture {
  background-image: url(#/images/drawers/popup_texture.png);
  background-color: #fdf4e3;
}
.popuphandle {
  background-image: url(#/images/drawers/popup_texture.png),
    -webkit-gradient(linear, left top, left bottom, from(#787878), to(#333333));
}
.close_popups {
  position: absolute;
  right: -25px;
  top: -25px;
  padding: 0;
  background: url(#/images/buttons/x-close.png) 0 0 no-repeat;
  height: 50px;
  width: 50px;
}
.observation_quote {
  color: rgba(0, 51, 204, 0.7);
}
.observation_quote::before {
  content: '"';
}
.observation_quote::after {
  content: '"';
}
.notes {
  color: rgba(0, 51, 204, 0.7);
  padding: 10px;
  font-family: GapsTown;
  font-size: 16px;
  -webkit-transform: rotate(-4deg);
  transform: rotate(-4deg);
  margin: auto;
}
.tags {
  position: absolute;
  top: 60px;
  width: 480px;
  background: url(#/images/buttons/tag_handle.png) left bottom no-repeat;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.6s ease;
}
.max-height-ref-10 {
  max-height: calc(100vh - 10rem);
}
@media (min-width: 768px) {
  .max-height-ref-10 {
    max-height: calc(100vh - 0rem);
  }
}
.max-height-ref-20 {
  max-height: calc(100vh - 10rem);
}
.hidden-if-no-height {
  display: none;
}
@media (min-height: 500px) {
  .hidden-if-no-height {
    display: block;
  }
}
.capfirst:first-letter {
  text-transform: uppercase;
}
.z-200 {
  z-index: 200;
}
.addcomma::after {
  content: ", ";
}
.addcomma:last-child::after {
  content: "";
}
* {
  -webkit-touch-callout: none;
}
.padding-timeline {
  height: calc(50vh + 1rem);
  min-height: calc(50vh + 1rem);
  overflow: hidden;
}
.text-gold {
  color: #9e883a;
}
.border-gold {
  border-color: #9e883a;
}
textarea,
input:not([type="checkbox"]):not([type="radio"]) {
  background-color: #101010;
  color: #e0e0e0;
}
.z-2 {
  z-index: 2;
}
.max-w-100 {
  max-width: 22rem;
}

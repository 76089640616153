@charset "UTF-8";
.slow {
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}
.fast {
  -webkit-transition: all 0.01s ease;
  -moz-transition: all 0.01s ease;
  -o-transition: all 0.01s ease;
}
#noCardCover {
  background-color: #000000;
  opacity: 0.25;
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
  display: block;
}
.tutorialsEvent {
  position: fixed;
  z-index: 10000000;
}
#cursor {
  width: 100px;
  height: 100px;
  opacity: 0.9;
  display: none;
  position: fixed;
  z-index: 200000;
}
#cursor .pulse {
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 30px;
  border-radius: 30px;
  position: absolute;
}
#cursor .dot {
  border: 10px solid #fff;
  background: transparent;
  -webkit-border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: pulse 0.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  position: absolute;
  top: -28px;
  left: -28px;
  opacity: 0;
}
@-webkit-keyframes "pulse" {
  #cursor 0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  #cursor 25% {
    -webkit-transform: scale(0.2);
    opacity: 0.1;
  }
  #cursor 50% {
    -webkit-transform: scale(0.4);
    opacity: 0.3;
  }
  #cursor 75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  #cursor 100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@font-face {
  font-family: GapsTown;
  font-weight: normal;
  src: url(#/fonts/gapstown_small/GapsS.ttf);
}
@font-face {
  font-family: GapsTown;
  font-weight: bold;
  src: url(#/fonts/gapstown_small/GapsS-b.ttf);
}
.font-normal {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
}
.font-bold {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
.body {
  background: #000;
  color: #fff;
  color: #ff00aa;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  min-height: 1080px;
  min-width: 1920px;
  height: 1080px;
  width: 1920px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}
.body img {
  -webkit-user-drag: none;
}
strong {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}
p {
  margin-bottom: 20px;
}
img {
  margin-bottom: 20px;
}
div.archive {
  position: absolute;
  top: 0;
  left: -490px;
  width: 500px;
  height: 1080px;
  box-sizing: border-box;
  background: black;
  color: white;
  padding: 50px 10px 10px 10px;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  z-index: 4000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.archive section {
  background-image: url(#/images/drawers/popup_texture.png),
    -webkit-gradient(linear, left top, left bottom, from(#787878), to(#333333));
  position: relative;
  margin: 0;
  padding: 0 0 0 0;
  height: 47px;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  box-sizing: border-box;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}
div.archive h1 {
  position: absolute;
  top: -24px;
  left: 80px;
  background: #787878;
  background-image: url(#/images/drawers/popup_texture.png);
  width: auto;
  padding: 0 20px 0 20px;
  font-weight: bold;
  color: white;
  line-height: 24px;
  border-radius: 5px 5px 0 0;
}
div.archive div.browser {
  max-height: 90vh;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
}
div.archive div.browser div.filters {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  overflow: auto;
}
div.archive div.browser img.scribble {
  padding: 40px;
}
div.archive div.browser div.map,
div.archive div.browser div.left {
  width: 320px;
  height: 640px;
  padding: 20px;
  box-sizing: border-box;
  margin: 0;
  float: left;
  overflow: hidden;
}
div.archive div.browser div.map img,
div.archive div.browser div.left img {
  max-width: 280px;
  opacity: 0.2;
}
div.archive div.browser div.map ul.shortcuts,
div.archive div.browser div.left ul.shortcuts {
  border-right: 1px solid white;
  padding: 0 0 0 10px;
}
div.archive div.browser div.map ul.shortcuts h2,
div.archive div.browser div.left ul.shortcuts h2 {
  line-height: 30px;
  text-transform: uppercase;
}
div.archive div.browser div.list {
  width: 400px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 30px 20px 30px 35px;
}
div.archive div.browser div.list h2.list_title {
  text-transform: uppercase;
  padding-top: 20px;
  line-height: 30px;
  clear: both;
}
div.archive div.browser div.list ul.list {
  margin: 0;
  padding: 0;
}
div.archive div.browser div.list ul.list li {
  list-style: none;
  margin: 0;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  width: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 0, 0, 0);
}
div.archive div.browser div.list ul.list li a {
  text-decoration: none;
  color: white;
  display: block;
  box-sizing: border-box;
  width: 400px;
}
div.archive div.browser div.list ul.list li a .popup_icon img {
  height: 40px;
  margin-right: 10px;
  float: left;
}
div.archive div.browser div.list ul.list li a figure {
  width: 298px;
  max-height: 140px;
  min-height: 60px;
  box-sizing: border-box;
  padding: 10px;
  background: rgba(255, 255, 255, 0);
  display: block;
  text-align: center;
  margin: 0;
}
div.archive div.browser div.list ul.list li a figure img {
  max-width: 280px;
  max-height: 120px;
  background: rgba(255, 0, 0, 0);
  padding: 0;
  margin: 0;
}
div.archive div.browser div.list ul.list li a h3 {
  color: white;
  line-height: 20px;
}
div.archive div.browser div.list ul.list li:last-child {
  border-bottom: none;
}
div.archive,
div.collection {
  box-shadow: 0 0 100px 50px rgba(0, 0, 0, 0.6);
}
div.archive div.expander,
div.collection div.expander {
  position: absolute;
  top: 0;
  right: -60px;
  width: 80px;
  height: 1080px;
  z-index: 3000;
  background: url(#/images/drawers/archive_handle.png) top left no-repeat;
}
div.archive div.expander div,
div.collection div.expander div {
  display: block;
  position: absolute;
  top: 440px;
  width: 80px;
  left: 0;
  height: 240px;
}
div.collection div.expander {
  position: absolute;
  top: 0;
  right: auto;
  left: -60px;
  background: url(#/images/drawers/collection_handle.png) top left no-repeat;
}
div.collection section h1 {
  position: absolute;
  top: -24px;
  left: 80px;
  background: #787878;
  background-image: url(#/images/drawers/popup_texture.png);
  width: auto;
  padding: 0 20px 0 20px;
  font-weight: bold;
  color: white;
  line-height: 24px;
  border-radius: 5px 5px 0 0;
}
.body.observations div.archive {
  box-shadow: 0 0 120px 90px rgba(0, 0, 0, 0.8);
}
div.archive section.tab_open {
  height: 100%;
  transition: all 0.6s ease;
  background-image: url(#/images/drawers/popup_texture.png),
    -webkit-gradient(linear, left top, left bottom, from(#787878), to(#333333));
  position: relative;
  cursor: default;
}
div.archive section.tab_open div.placeholder,
div.archive section.tab_open div.browser {
  display: block;
  transition: all 0.6s ease;
}
div.collection {
  position: absolute;
  top: 0;
  left: 1910px;
  width: 500px;
  height: 1080px;
  box-sizing: border-box;
  background: black;
  color: white;
  padding: 50px 10px 10px 10px;
  transition: all 0.6s ease;
  z-index: 4000;
}
div.popups {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  right: 0;
  overflow: visible;
}
div.popup {
  position: absolute;
  top: 80px;
  left: 660px;
  width: 480px;
  padding-top: 63px;
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.4);
}
div.popup h2.subtitle {
  padding: 5px 0px 5px 0px;
}
div.popup div.jamtliid {
  float: right;
  width: 120px;
  text-align: right;
}
div.popup div.factbox {
  float: right;
  clear: right;
  width: 120px;
  border: 1px solid black;
  border-bottom: 0;
  margin-left: 5px;
}
div.popup div.factbox .box {
  border-bottom: 1px solid black;
  padding: 4px;
}
div.popup div.handle {
  background-image: url(#/images/drawers/popup_texture.png),
    -webkit-gradient(linear, left top, left bottom, from(#787878), to(#333333));
  position: relative;
  width: 480px;
  height: 63px;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 10px 0 0 10px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.4);
}
div.popup div.handle .popup_icon img {
  height: 40px;
  margin-right: 10px;
  float: left;
}
div.popup div.handle h1 {
  color: white;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  height: 20px;
  width: 400px;
  overflow: hidden;
}
div.popup div.handle p.category {
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  padding: 0;
}
div.popup div.handle div.add_to_collection {
  position: absolute;
  right: 20px;
  top: 10px;
  height: 40px;
  width: 40px;
}
div.popup div.handle div.add_to_collection a {
  height: 40px;
  width: 39px;
  display: block;
  text-decoration: none;
  color: white;
  background: url(#/images/handles/add_to_collection.png) 0 0 no-repeat;
  overflow: hidden;
  text-indent: -200px;
}
div.popup div.handle div.add_to_collection a:active,
div.popup div.handle div.add_to_collection a.selected {
  background-position: 0 -100px;
}
div.popup div.handle a.close_popups {
  position: absolute;
  left: 454px;
  top: -22px;
  padding: 0;
  background: url(#/images/buttons/x-close.png) 0 0 no-repeat;
  height: 50px;
  width: 50px;
}
div.popup div.tags {
  position: absolute;
  top: 60px;
  width: 480px;
  background: url(#../images/buttons/tag_handle.png) left bottom no-repeat;
  box-sizing: border-box;
  overflow: hidden;
}
div.popup div.tags .tag_handle {
  width: 460px;
  height: 50px;
}
div.popup div.tags .tag_handle a {
  text-decoration: none;
  position: absolute;
  left: 220px;
  border-radius: 60px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-indent: -200px;
}
div.popup div.tags ul.tag_list {
  display: none;
  width: 440px;
  margin: 0;
  padding: 10px 10px 10px 20px;
}
div.popup div.tags ul.tag_list li {
  display: block;
  margin: 0;
  padding: 10px;
  width: 420px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
div.popup div.tags ul.tag_list li a.tag {
  background: white;
  color: black;
  margin: 0;
  text-decoration: none;
  line-height: 20px;
  display: block;
  width: 100%;
  white-space: nowrap;
  background: url(#../images/buttons/tag_arrow.png) 0px 2px no-repeat;
  background-position-x: 8px;
  padding-left: 40px;
}
div.popup div.tags ul.tag_list li a.tag:first-letter {
  text-transform: uppercase;
}
div.popup div.tags ul.tag_list li a.tip_animal {
  background: url(#../images/handles/Djur.png) left center no-repeat;
  background-size: 30px 30px;
  text-decoration: none;
  color: black;
  padding: 0 10px 0 40px;
  display: block;
}
div.popup div.tags ul.tag_list li a.tip_stories {
  background: url(#../images/handles/Sagor.png) left center no-repeat;
  background-size: 30px 30px;
  text-decoration: none;
  color: black;
  padding: 10px 10px 10px 40px;
  display: block;
}
div.popup div.tags ul.tag_list li a.tip_science {
  background: url(#../images/handles/Vetenskap.png) left center no-repeat;
  background-size: 30px 30px;
  text-decoration: none;
  color: black;
  padding: 0 10px 0 40px;
  display: block;
}
div.popup div.tags ul.tag_list li a.tip_history {
  background: url(#../images/handles/Historia.png) left center no-repeat;
  background-size: 30px 30px;
  text-decoration: none;
  color: black;
  padding: 0 10px 0 40px;
  display: block;
}
div.popup div.tags ul.tag_list li a.tip_observations {
  background: url(#../images/handles/Observationer.png) left center no-repeat;
  background-size: 30px 30px;
  text-decoration: none;
  color: black;
  padding: 0 10px 0 40px;
  display: block;
}
div.popup div.tags ul.tag_list li a.tip_hypothesis {
  background: url(#../images/handles/Hypoteser.png) left center no-repeat;
  background-size: 30px 30px;
  text-decoration: none;
  color: black;
  padding: 0 10px 0 40px;
  display: block;
}
div.popup div.tags ul.tag_list li a.tip_about {
  background: url(#../images/handles/Centeret.png) left center no-repeat;
  background-size: 30px 30px;
  text-decoration: none;
  color: black;
  padding: 0 10px 0 40px;
  display: block;
}
div.popup div.tags ul.tag_list li ul.tip_open {
  display: block;
  color: black;
  height: 60px;
}
div.popup div.tags ul.tag_list li:last-child {
  border-bottom: none;
}
div.popup div.tags ul.tag_list.curtain_open {
  display: block;
}
div.popup .tab .tagged_item_picker {
  display: none;
}
div.popup .tab.tab_open .tagged_item_picker {
  display: block;
}
div.popup .tab.tab_open .tagged_item_picker li {
  width: 380px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
div.popup .tab.tab_open .tagged_item_picker li:last-child {
  border-bottom: none;
}
div.popup ul.tag_list li.tab.tab_open a.tag {
  background: url(#../images/buttons/tag_arrow.png) 0px -97px no-repeat;
  background-position-x: 8px;
}
div.popup ul.tagged_item_picker {
  background: white;
  width: 420px;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
}
div.popup ul.tagged_item_picker li {
  margin: 0;
  padding: 0;
}
div.popup ul.tagged_item_picker li a {
  background: white;
  color: black;
  text-decoration: none;
  box-sizing: border-box;
  padding-left: 40px;
  margin: 0;
  border-bottom: none;
  position: relative;
  display: block;
}
div.popup ul.tagged_item_picker li:last-child {
  border-bottom: none;
}
div.popup div.scrollport {
  width: 480px;
  box-sizing: border-box;
  height: auto;
  background-image: url(#/images/drawers/popup_texture.png);
  background-color: #fdf4e3;
}
div.popup .popup_gradient {
  height: 30px;
  width: 480px;
  display: none;
}
div.popup div.contents {
  max-height: 700px;
  overflow: hidden;
  position: relative;
}
div.popup div.contents div.page {
  color: rgba(0, 0, 0, 0.7);
  padding: 50px 60px 40px 60px;
}
div.popup div.contents div.page h1 {
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
  text-transform: normal;
  color: #000000;
  padding-bottom: 20px;
}
div.popup div.contents div.page h1.h1_animal {
  padding-bottom: 0px;
}
div.popup div.contents div.page h2 {
  font-size: 14px;
  line-height: 20px;
  text-transform: normal;
  margin-bottom: 20px;
}
div.popup div.contents div.page section {
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.7);
}
div.popup div.contents div.page section a {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  border-bottom: 1px dotted rgba(0, 51, 204, 0.5);
}
div.popup div.contents div.page h3 {
  display: inline;
  font-weight: bold;
  padding-right: 4px;
}
div.popup div.contents div.page p.observation_quote {
  font-size: 16px;
  padding-top: 20px;
  color: rgba(0, 51, 204, 0.7);
}
div.popup div.contents div.page p {
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.7);
}
div.popup div.contents div.page img {
  max-width: 100%;
}
div.popup div.contents div.page .notes p {
  color: rgba(0, 51, 204, 0.7);
  max-width: 60%;
  padding: 10px;
  font-family: GapsTown;
  font-size: 16px;
  transform: rotate(-8deg);
}
div.popup div.contents div.page .notes p:last-child {
  padding-left: 80px;
  transform: rotate(-4deg);
}
div.popup div.contents div.page p.short_description {
  color: #000000;
}
div.popup div.contents div.page .observation_pdf {
  max-width: 100%;
}
div.popup div.contents div.page .observation_pdf img:first-child {
  padding-top: 10px;
}
div.popup div.contents div.page .minimap img {
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
}
div.popup div.contents div.page ul {
  color: rgba(0, 0, 0, 0.7);
  list-style-type: circle;
  margin-left: 18px;
}
div.popup div.contents div.page ul.comma_separated {
  color: rgba(0, 0, 0, 0.7);
  display: inline;
  margin: 0px;
}
div.popup div.contents div.page ul.comma_separated li {
  display: inline;
}
div.popup div.contents div.page ul.comma_separated li:after {
  content: ", ";
}
div.popup div.contents div.page ul.comma_separated li:last-child:after {
  content: "";
}
#researcher_profile {
  min-height: 350px;
  background-image: url(#/images/multi/paper_texture.png);
  background-color: #fdf4e3;
}
#researcher_profile .handle h1 {
  padding: 10px 10px 10px 20px;
}
#researcher_profile .page {
  padding: 20px 60px 0 60px;
  position: absolute;
  top: 60px;
}
#researcher_profile .page .field {
  position: relative;
  padding-bottom: 20px;
}
#researcher_profile .page .field h1 {
  color: black;
  line-height: 20px;
  display: block;
  text-transform: uppercase;
}
#researcher_profile .page .field h1 span {
  border-bottom: 1px solid red;
}
#researcher_profile .page .field textarea,
#researcher_profile .page .field input {
  border: 0;
  line-height: 20px;
  resize: none;
  width: 360px;
  min-height: 40px;
  background: transparent;
  overflow: visible;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: block;
}
#researcher_profile .page .field textarea:focus,
#researcher_profile .page .field input:focus {
  border-radius: 3px;
  box-shadow: 0px 1px 1px white, #9b9b9b 0px 1px 1px inset;
  background-color: rgba(130, 130, 130, 0.2);
  border: 0;
}
#researcher_profile .page .field input {
  min-height: 20px;
}
#researcher_profile .page .field .checkbox {
  width: 40px;
  height: 40px;
  -webkit-appearance: none;
  background-color: #fffbf3;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
}
#researcher_profile .page .field .checkbox:active,
#researcher_profile .page .field .checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}
#researcher_profile .page .field .checkbox:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}
#researcher_profile .page .field .checkbox:checked:after {
  content: "\2714";
  font-size: 30px;
  position: absolute;
  top: 0px;
  left: 3px;
  color: #99a1a7;
  padding: 10px 5px 5px 5px;
}
#researcher_profile .page .field p {
  display: block;
  color: black;
  display: inline-block;
  width: 250px;
  padding-left: 20px;
  line-height: 20px;
}
b {
  font-weight: bold;
}
.button {
  border: 0;
  padding: 0 0 0 10px;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  line-height: 30px;
  background: url(#/images/buttons/button_left.png) left 0 no-repeat;
  color: white;
  display: inline-block;
  display: block;
  width: auto;
  float: left;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
}
.button b {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  padding: 0 20px 2px 10px;
  display: inline-block;
  background: url(#/images/buttons/button.png) right 0 no-repeat;
  cursor: pointer;
  display: block;
  width: auto;
}
.button.selected {
  background-position: left -100px;
}
.button.selected b {
  padding: 2px 20px 0 10px;
  background-position: right -100px;
  color: white;
  padding-top: 2px;
}
div.keyboard_container {
  width: 720px;
  padding: 40px;
  border-radius: 20px;
  position: absolute;
  background: grey;
  padding: 20px;
  margin: 0;
  -webkit-border-radius: 20px;
  box-shadow: 0 5px 20px 5px rgba(0, 0, 0, 0.2), 0 20px 0px 0px #808080;
  left: 0;
}
div.keyboard_container ul.keyboard {
  margin: 0;
  padding: 0;
  list-style: none;
}
div.keyboard_container ul.keyboard li {
  float: left;
  margin: 0 10px 15px 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #636363;
  box-shadow: 0px 5px 0px 0 #282828;
  color: white;
  -webkit-border-radius: 3px;
}
div.keyboard_container ul.keyboard li:active,
div.keyboard_container ul.keyboard li.selected {
  position: relative;
  top: 3px;
  box-shadow: 0px 2px 0px 0 #282828;
}
div.keyboard_container ul.keyboard .capslock,
div.keyboard_container ul.keyboard .tab,
div.keyboard_container ul.keyboard .left-shift {
  clear: left;
}
div.keyboard_container ul.keyboard .tab,
div.keyboard_container ul.keyboard .delete {
  width: 70px;
}
div.keyboard_container ul.keyboard .capslock {
  width: 80px;
}
div.keyboard_container ul.keyboard .return {
  width: 80px;
}
div.keyboard_container ul.keyboard .left-shift {
  width: 95px;
}
div.keyboard_container ul.keyboard .right-shift {
  width: 115px;
}
div.keyboard_container ul.keyboard .lastitem {
  margin-right: 0;
}
div.keyboard_container ul.keyboard .uppercase {
  text-transform: uppercase;
}
div.keyboard_container ul.keyboard .space {
  clear: left;
  width: 720px;
}
div.keyboard_container ul.keyboard .on {
  display: none;
}
#popup_keyboard {
  background-color: #666666;
  position: fixed;
  top: 600px;
  left: 580px;
  display: none;
}
